import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "atomic-layout";
import media from "styled-media-query";
import useMatchMedia from "use-match-media-hook";
import { graphql } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Page, Title, Bullets, ScrollIndicator } from "../components";

const Hero = styled(Box)`
  position: relative;
  z-index: 2;

  ${media.lessThan("medium")`
    margin-top: 2vw;
  `}

  ${Title} {
    position: absolute;
    bottom: 100px;
    left: 200px;
    z-index: 3;

    ${media.lessThan("medium")`
      font-size: 36px;
      bottom: 10px;
      left: 5%;
    `}
  }

  :after {
    content: "";
    width: 96%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const Container = styled(Box)`
  width: 96%;
  margin: 0 auto;
  padding-top: 200px;
  position: relative;
  z-index: 1;

  ${media.lessThan("medium")`
    padding-top: 60px;
    width: 90%;
  `}

  :after {
    content: "";
    width: 80%;
    height: 1590px;
    background: #27494a;
    opacity: 0.1;
    position: absolute;
    top: -50vh;
    left: -2%;

    ${media.lessThan("medium")`
      display: none;
    `}
  }

  & > * + * {
    margin-top: 30px;
  }
`;

const Grid = styled(Box)`
  position: relative;
  z-index: 3;
  margin-top: 300px;
  margin-bottom: 150px;

  ${media.lessThan("medium")`
    margin-top: 60px;

    > div {
      width: 100% !important;
      height: auto !important;
      margin-bottom: 10px !important;
      margin-top: 0 !important;
    }

    .gatsby-image-wrapper {
      width: 100%;
      max-height: 500px;
    }

    .info-box {
      position: relative !important;
      width: 100% !important;
      padding: 0;
      display: none;
    }
  `}
`;

const Texts = styled.div`
  p {
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    color: #6c6c6c;
    position: relative;
    z-index: 2;
    :not(:first-of-type) {
      margin-top: 30px;
    }
  }
`;

const Slides = ({ images }) => {
  const [current, setCurrent] = useState(0);

  const image = images[current];

  return (
    <Box width="100%" height="clamp(80vh, 40vw, 1080px)" flex flexWrap="wrap" marginTop="5vw">
      <Box width="100%" height="100%">
        <AnimatePresence exitBeforeEnter>
          <Box
            as={motion.div}
            width="100%"
            height="100%"
            key={image.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { type: "spring", bounce: 0.1, duration: 0.4 } }}
            exit={{ opacity: 0 }}
          >
            <GatsbyImage
              image={getImage(image.gatsbyImageData)}
              style={{ height: "100%" }}
              title={image.title}
              alt={image.description}
            />
          </Box>
        </AnimatePresence>
      </Box>
      <Box flex justifyContent="center" width="100%" marginTop={60}>
        <Bullets
          total={images.length}
          active={current}
          onChangeCurrent={(index) => setCurrent(index)}
        />
      </Box>
    </Box>
  );
};

const Estrutura = ({ data }) => {
  const { node } = data.allContentfulEstrutura.edges[0];
  const [desktop] = useMatchMedia(["(min-width: 800px)"]);

  return (
    <Page title="Acomodações" backgroundColor="white">
      <Hero paddingHorizontal="2%">
        <Box
          as={GatsbyImage}
          image={getImage(node.capa.gatsbyImageData)}
          title={node.capa.title}
          alt={node.capa.description}
          style={{ maxHeight: "calc(100vh - 140px)" }}
        />
        <Title color="white">{node.titulo}</Title>
        {desktop && <ScrollIndicator />}
      </Hero>

      <Container>
        <Texts dangerouslySetInnerHTML={{ __html: node.resumo.childMarkdownRemark.html }} />

        <Grid
          width="100%"
          flex
          flexDirection="row"
          flexWrap="wrap"
          gap="5%"
          align="center"
          justifyContent="center"
        >
          <Box width="55%" height="clamp(480px, 65vw, 1060px)">
            <GatsbyImage
              image={getImage(node.galeria[0].gatsbyImageData)}
              title={node.galeria[0].title}
              alt={node.galeria[0].description}
              style={{ height: "100%" }}
            />
          </Box>
          <Box width="40%" height="clamp(320px, 40vw, 505px)" marginTop="auto">
            <GatsbyImage
              image={getImage(node.galeria[1].gatsbyImageData)}
              title={node.galeria[1].title}
              alt={node.galeria[1].description}
              style={{ height: "100%" }}
            />
          </Box>

          <Box
            width="100%"
            height="clamp(700px, 40vw, 1080px)"
            maxWidth="100%"
            style={{ position: "relative" }}
            marginTop="5vw"
            marginBottom="5vw"
          >
            <GatsbyImage
              image={getImage(node.galeria[2].gatsbyImageData)}
              title={node.galeria[2].title}
              alt={node.galeria[2].description}
              style={{ height: "100%" }}
            />

            {desktop && (
              <Box
                width="clamp(280px, 90vw, 600px)"
                padding="100px"
                paddingLeft={0}
                style={{ background: "white", position: "absolute", bottom: 0, left: 0, zIndex: 2 }}
                className="info-box"
              >
                <Texts dangerouslySetInnerHTML={{ __html: node.texto.childMarkdownRemark.html }} />
              </Box>
            )}
          </Box>

          {!desktop && (
            <Texts dangerouslySetInnerHTML={{ __html: node.texto.childMarkdownRemark.html }} />
          )}

          <Box width="35%" height="clamp(500px, 30vw, 880px)">
            <GatsbyImage
              image={getImage(node.galeria[3].gatsbyImageData)}
              title={node.galeria[3].title}
              alt={node.galeria[3].description}
              style={{ height: "100%" }}
            />
          </Box>
          <Box width="60%" height="clamp(500px, 30vw, 880px)">
            <GatsbyImage
              image={getImage(node.galeria[4].gatsbyImageData)}
              title={node.galeria[4].title}
              alt={node.galeria[4].description}
              style={{ height: "100%" }}
            />
          </Box>

          <Slides images={node.galeria} />
        </Grid>
      </Container>
    </Page>
  );
};

export const query = graphql`
  query($slug: String!) {
    allContentfulEstrutura(filter: { node_locale: { eq: "en-US" }, slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          capa {
            gatsbyImageData(layout: FULL_WIDTH)
            title
            description
          }
          galeria {
            id
            gatsbyImageData(resizingBehavior: FILL, layout: FULL_WIDTH)
            description
            title
          }
          resumo {
            childMarkdownRemark {
              html
            }
          }
          texto {
            childMarkdownRemark {
              html
            }
          }
          titulo
        }
      }
    }
  }
`;

export default Estrutura;
